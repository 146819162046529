import axios from './axios';
import { api } from './app.config';
import { isArray } from '../helpers/javascript';

export const types = ({ team, jwt }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/entity-types`,
      headers: {
        Authorization: `Bearer ${jwt}`,
        'X-Mission-Control-Team': team
      }
    })
      .then(response => {
        if (isArray(response.data)) resolve(response.data);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });
