/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { create } from '../../services/team.services';
import CustomDialog from '../Dialog';
import { api } from '../../services/app.config';
import { GoogleIcon, FacebookIcon } from '../Icon';
import { getProfile, register, sendRecoveryCode } from '../../services/security.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import ActionAlert from '../Alert';
import translate from '../../data/translations';
import { gtmFireEvent } from '../../helpers/gtm';
import UserProfessionJob from '../UserProfessionJob';
import { isArray, isFunction, isObject } from '../../helpers/javascript';
import useStyles from './styles';
import { plans } from '../../services/stripe.services';
import { useLocalStorage } from '../../helpers/browser';
import { validateUsername, validateEmail } from '../../helpers/schemaValidation';
import { MultiStateButton } from '../MultiStateButton';

const SignUp = ({
  forwardRef,
  onLogin,
  audience,
  showModal,
  showFacebook,
  showGoogle,
  showLogin,
  showForm,
  defaultEmail,
  showEmailReadonly,
  onSignUp,
  showUsername,
  tags,
  size
}) => {
  const classes = useStyles();
  const [isFormVisible, setIsFormVisible] = React.useState(showForm);
  const [username, setUserName] = React.useState(!showUsername ? defaultEmail : '');
  const [email, setEmail] = React.useState(defaultEmail);
  const [isValidEmail, setIsValidEmail] = React.useState(null);
  const [isValidUsername, setIsValidUsername] = React.useState(null);
  const [globalError, setGlobalError] = React.useState();
  const [successError, setSuccessError] = React.useState();
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const [invalidUsernameMessages, setInvalidUsernameessages] = React.useState('invalidFieldError');
  const dialogRef = React.useRef(null);
  const iframeRef = React.useRef(null);
  const callbackRef = React.useRef();
  const pendingUser = React.useRef(null);
  const pendingPlan = React.useRef(null);
  const { dispatch } = useAuthentication();
  const lc = useLocalStorage();

  const handleEscape = () => {
    if (dialogRef && dialogRef.current) dialogRef.current.hide();
    setIsFormVisible(false);
    setUserName('');
    setEmail('');
    setPassword('');
    setIsValidEmail(true);
    setIsValidUsername(true);
  };

  React.useEffect(() => {
    if (!showModal) window.addEventListener('message', handleLogin);
    return () => {
      if (!showModal) window.removeEventListener('message', handleLogin);
    };
  });

  React.useEffect(() => {
    setGlobalError('');
  }, [username, email, password]);

  const logged = payload => {
    window.dataLayer.push({
      event: 'user',
      email: payload.user.email,
      name:
        payload.user && payload.user.profile && payload.user.profile.name
          ? payload.user.profile.name
          : payload.user.username
    });
    console.log('UPDATE_PAYMENT', payload);
    dispatch({ type: 'UPDATE_PAYMENT', plan: payload.plan || false });
    localStorage.setItem('user', JSON.stringify(payload.user));
    if (showModal) handleEscape();
    dispatch({ type: 'LOGIN' });
    if (isFunction(callbackRef.current)) callbackRef.current(payload);
    callbackRef.current = null;
    onSignUp();
  };

  const handleLogin = e => {
    const {
      data: { event, payload }
    } = e;
    if (event === 'on-woxo-login-success') {
      setProfile(
        isObject(payload.user.profile)
          ? {
              ...payload.user.profile,
              jwt: payload.user.jwt,
              team: payload.user.team,
              username: payload.user.username,
              email: payload.user.email
            }
          : {
              jwt: payload.user.jwt,
              team: payload.user.team,
              username: payload.user.username,
              email: payload.user.email
            }
      );
      pendingUser.current = payload.user;
      pendingPlan.current = payload.plan;
      if (
        payload.user.profile &&
        payload.user.profile.profession &&
        payload.user.profile.entityType
      )
        logged(payload);
    } else if (event === 'on-woxo-login-fail') {
      const { error, user, plan } = payload;
      if (user) pendingUser.current = user;
      if (plan) pendingPlan.current = plan;
      setGlobalError(error);
    }
  };

  React.useImperativeHandle(forwardRef, () => ({
    show: fn => {
      callbackRef.current = fn;
      setSuccessError('');
      setIsLoading(false);
      setProfile(null);
      setGlobalError(false);
      window.addEventListener('message', handleLogin);
      dialogRef.current.show();
    },
    hide: () => {
      window.removeEventListener('message', handleLogin);
      handleEscape();
    }
  }));

  const handleForm = () => {
    setGlobalError('');
    setIsFormVisible(true);
  };

  const handleUsername = e => {
    setUserName(e.target.value);
  };

  const handleEmail = e => {
    setEmail(e.target.value);
    if (!showUsername) setUserName(e.target.value);
  };

  const handlePassword = e => setPassword(e.target.value);

  const handleConnect = connector => {
    setGlobalError('');
    setIsLoading(true);
    const width = 600;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;
    lc.save('user-audience', audience);
    iframeRef.current = window.open(
      `${api}/connect/${connector}`,
      'Login',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},dialog=yes,top=${top},left=${left}`
    );
    const timer = setInterval(() => {
      if (iframeRef.current.closed) {
        clearInterval(timer);
        setIsLoading(false);
      }
    }, 1000);
  };

  const signUp = () => {
    if (!isLoading) {
      setGlobalError('');
      setIsLoading(true);
      lc.save('user-audience', audience);
      register({
        provider: 'local',
        data: { username, email, password }
      })
        .then(res => Promise.all([res, create({ ...res, userTags: tags })]))
        .then(([res, team]) => {
          return Promise.all([
            res,
            team,
            getProfile(res.jwt, team._id),
            plans({
              headers: {
                Authorization: `Bearer ${res.jwt}`,
                'X-Mission-Control-Team': team._id
              }
            })
          ]);
        })
        .then(([res, team, extra, plan]) => {
          const user = {
            jwt: res.jwt,
            username: res.user.username,
            email: res.user.email,
            team: team._id,
            branding: (isArray(team.brandings) && team.brandings[0]) || null,
            provider: 'local',
            profile: extra.profile,
            confirmed: res.user.confirmed
          };
          gtmFireEvent({
            event: 'onSecurity',
            category: 'security',
            action: 'register',
            label: 'local-register'
          });
          setIsLoading(false);
          setProfile(
            isObject(user.profile)
              ? {
                  ...user.profile,
                  jwt: user.jwt,
                  team: user.team,
                  username: user.username,
                  email: user.email
                }
              : { jwt: user.jwt, team: user.team, username: user.username, email: user.email }
          );
          pendingUser.current = user;
          pendingPlan.current = plan;
          if (user.profile && user.profile.profession && user.profile.entityType)
            logged({ user, plan });
        })
        .catch(e => {
          setGlobalError(e.message);
          setIsLoading(false);
        });
    }
  };

  const handleValidateUsername = () => {
    setIsValidUsername(validateUsername(username));
  };

  const handleValidateEmail = () => {
    setIsValidEmail(validateEmail(email));
  };

  const handleActivate = () => {
    setIsLoading(true);
    setGlobalError('');
    sendRecoveryCode({
      headers: {
        Authorization: `Bearer ${pendingUser.current.jwt}`,
        'X-Mission-Control-Team': pendingUser.current.team
      }
    })
      .then(() => {
        setIsLoading(false);
        setSuccessError('You have received an email to recover your account. Please check it!');
      })
      .catch(() => {
        setGlobalError('Error occurred during enabling your account. Please {messages.try.again}');
        setIsLoading(false);
      });
  };

  const handleUpdateProfile = ({ profession, entityType, name }) => {
    setProfile(prevState =>
      isObject(prevState) ? { ...prevState, profession, entityType } : { profession, entityType }
    );
    if (pendingUser.current && !pendingUser.current.profile) pendingUser.current.profile = {};
    pendingUser.current.profile.profession = profession;
    pendingUser.current.profile.entityType = entityType;
    logged({
      user: { ...pendingUser.current, profile: { profession, entityType, name } },
      plan: pendingPlan.current
    });

    if (showModal) handleEscape();
  };

  const signUpContainer = () => (
    <Box className={clsx(classes.container, size === 'medium' ? classes.container__medium : '')}>
      {profile !== null && (!profile || !profile.profession || !profile.entityType) && (
        <UserProfessionJob {...profile} onChange={handleUpdateProfile} />
      )}
      {profile === null && (
        <>
          <Box className={classes.title} textAlign="center">
            Sign up
          </Box>
          <Box
            className={clsx(
              classes.subTitle,
              showGoogle || showFacebook ? '' : classes.paddingBottom
            )}
            textAlign="center"
          >
            It’s just to help you to recover your work later on
          </Box>
          {(globalError || successError) && (
            <ActionAlert
              type={successError ? 'success' : 'error'}
              message={successError || globalError}
              className={classes.alert}
              modifiers={{
                'sign.up.link.login': onLogin,
                'message.here': handleActivate,
                'messages.try.again': handleActivate
              }}
            />
          )}
          {showGoogle && (
            <Box className={classes.socialContainer}>
              <Button
                data-name="google-connect-btn"
                data-type="btn-click"
                className={classes.socialButton}
                onClick={() => handleConnect('google')}
              >
                <GoogleIcon />
                Connect with Google
              </Button>
            </Box>
          )}
          {showFacebook && (
            <Box className={classes.socialContainer}>
              <Button
                data-name="fb-connect-btn"
                data-type="btn-click"
                className={classes.socialButton}
                onClick={() => handleConnect('facebook')}
              >
                <FacebookIcon />
                Connect with Facebook
              </Button>
            </Box>
          )}
          {!isFormVisible && (
            <Box className={classes.socialContainer}>
              <Button
                data-name="email-connect-btn"
                data-type="btn-click"
                className={clsx(classes.socialButton, 'form-button')}
                onClick={handleForm}
              >
                Sign up with your e-mail
              </Button>
            </Box>
          )}
          {isFormVisible && (
            <form className={clsx(classes.form, 'signup-form')}>
              {showUsername && (
                <Box className={classes.formControl}>
                  <label htmlFor="#signup-name" className={classes.label}>
                    <Box className={classes.formTitle}>Username</Box>
                    {!isValidUsername && isValidUsername !== null && (
                      <ActionAlert
                        icon={<ReportProblemOutlinedIcon />}
                        variant="text"
                        message={translate(invalidUsernameMessages, 'en')}
                        className={classes.formAlert}
                      />
                    )}
                    <input
                      className={clsx(
                        classes.formInput,
                        isValidUsername === false ? 'invalid' : null
                      )}
                      type="text"
                      id="signup-name"
                      onChange={handleUsername}
                      autoComplete="nope"
                      placeholder="jane.doe"
                      onBlur={handleValidateUsername}
                    />
                  </label>
                </Box>
              )}
              <Box className={classes.formControl}>
                <label htmlFor="#signup-email" className={classes.label}>
                  <Box className={classes.formTitle}>E-mail</Box>
                  {!isValidEmail && isValidEmail !== null && (
                    <ActionAlert
                      icon={<ReportProblemOutlinedIcon />}
                      variant="text"
                      message={translate('invalidFieldError', 'en')}
                      className={classes.formAlert}
                    />
                  )}
                  <input
                    className={clsx(classes.formInput, isValidEmail === false ? 'invalid' : null)}
                    type="email"
                    id="signup-email"
                    onChange={handleEmail}
                    autoComplete="off"
                    readOnly
                    onFocus={e => {
                      if (!showEmailReadonly) e.target.removeAttribute('readonly');
                    }}
                    placeholder="jane@example.com"
                    onBlur={handleValidateEmail}
                    defaultValue={email}
                  />
                </label>
              </Box>
              <Box className={classes.formControl}>
                <label htmlFor="#signup-password" className={classes.label}>
                  <Box className={classes.formTitle}>Password</Box>
                  <input
                    className={classes.formInput}
                    type="password"
                    id="signup-password"
                    onChange={handlePassword}
                    autoComplete="off"
                    readOnly
                    onClick={e => e.target.removeAttribute('readonly')}
                    onFocus={e => e.target.removeAttribute('readonly')}
                    placeholder="*******"
                  />
                </label>
              </Box>
              <Box className={classes.socialContainer}>
                <MultiStateButton
                  className={clsx(classes.socialButton, 'form-button')}
                  onClick={signUp}
                  disabled={
                    !password ||
                    !password.trim() ||
                    !email ||
                    !email.trim() ||
                    !username ||
                    !username.trim() ||
                    (!defaultEmail && !isValidEmail) ||
                    (showUsername && !isValidUsername)
                  }
                  isLoading={!!isLoading}
                >
                  Start now!
                </MultiStateButton>
              </Box>
            </form>
          )}
          <Box className={classes.legal}>
            By signing up, you agree to the
            <a className={classes.link} href="/legal/terms-of-service">
              Terms of Services
            </a>
            and
            <a className={classes.link} href="/legal/privacy-policy">
              Privacy Policy
            </a>
          </Box>
          {showLogin && (
            <Box className={classes.login}>
              Already signed up?
              <Button className={classes.loginButton} onClick={onLogin}>
                Login
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );

  return showModal ? (
    <CustomDialog
      forwardRef={dialogRef}
      fullWidth
      className={classes.root}
      maxWidth="md"
      onEscapeKeyDown={handleEscape}
      useClose
      isLoading={isLoading}
      onClose={handleEscape}
      loaderBackgroundColor="rgba(255, 255, 255, 15%)"
    >
      {signUpContainer()}
    </CustomDialog>
  ) : (
    signUpContainer()
  );
};

SignUp.propTypes = {
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.any)]),
  onLogin: PropTypes.func,
  audience: PropTypes.string,
  showModal: PropTypes.bool,
  showGoogle: PropTypes.bool,
  showFacebook: PropTypes.bool,
  showLogin: PropTypes.bool,
  showForm: PropTypes.bool,
  showEmailReadonly: PropTypes.bool,
  onSignUp: PropTypes.func,
  showUsername: PropTypes.bool,
  defaultEmail: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string
};

SignUp.defaultProps = {
  forwardRef: () => {},
  onLogin: () => {},
  audience: 'widget',
  showModal: true,
  showGoogle: true,
  showFacebook: true,
  showLogin: true,
  showForm: false,
  showEmailReadonly: false,
  onSignUp: () => {},
  showUsername: true,
  defaultEmail: '',
  tags: [],
  size: 'large'
};

export default SignUp;
