import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import Loader from '../Loader';
import ActionAlert from '../Alert';
import RemoteAutoComplete from '../RemoteAutoComplete';
import { types } from '../../services/entity.services';
import { setProfile } from '../../services/security.service';
import translate from '../../data/translations';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const ListSkeleton = () => {
  const classes = useStyles();
  const items = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <Grid container className={classes.gridContainer}>
      {items.map(item => (
        <Grid key={item} item xs={6} sm={6} lg={6} xl={6}>
          <Box className={clsx(classes.item, 'loader')}>Loading...</Box>
        </Grid>
      ))}
    </Grid>
  );
};

const UserProfessionJob = ({
  jwt,
  team,
  profession,
  entityType,
  username,
  name: propName,
  email,
  onChange
}) => {
  const classes = useStyles();
  const [globalError, setGlobalError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [loadingList, setLoadingList] = React.useState(false);
  const [profileProfession, setProfileProfession] = React.useState(profession);
  const [profileType, setProfileType] = React.useState(entityType);
  const [entityTypes, setEntityTypes] = React.useState([]);
  const [name, setName] = React.useState(propName);
  const [invalidName, setInvalidName] = React.useState(false);
  const [showOtherProfession, setShowOtherProfession] = React.useState(false);
  const [otherProfession, setOtherProfession] = React.useState('');
  const [showOtherType, setShowOtherType] = React.useState(false);
  const [otherType, setOtherType] = React.useState('');
  const [profileGoal, setProfileGoal] = React.useState('');
  const [surveyCompleted, setSurveyCompleted] = React.useState(false);

  React.useEffect(() => {
    setLoadingList(true);
    types({ jwt, team })
      .then(response => {
        setEntityTypes(response);
        setLoadingList(false);
      })
      .catch(() => {
        setLoadingList(false);
      });
  }, []);

  const handleProfession = (e, value) => {
    setProfileProfession(value && value._id);
    // setProfileProfession(value.name);
    // if (value.name === 'Other')
    //   setShowOtherProfession(true);
    // else
    //   setShowOtherProfession(false);
  };

  const handleType = value => {
    setProfileType(value);
  };


  // const handleType = (e, value) => {
  //   setProfileType(value.name);
  //   if (value.name === 'Other')
  //     setShowOtherType(true);
  //   else
  //     setShowOtherType(false);
  // };

  const handleStart = () => {
    if (name !== '') {
      setInvalidName(false);
      setLoading(true);
      setGlobalError('');
      let profession = profileProfession;
      let type = profileType
      if (profession === 'Other')
        profession = otherProfession;
      if (type === 'Other')
        type = otherType;
      setProfile(
        { name, username, email, profession: profession, entityType: type },
        { jwt, team }
      )
        .then(() => {
          // setSurveyCompleted(true);
          onChange({ profession: profileProfession, entityType: profileType, name });
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          setGlobalError(translate('error.network'));
        });
    } else {
      setInvalidName(true);
    }
  };

  // const handleFinish = () => {
  //   let profession = profileProfession;
  //   let type = profileType
  //   if (profession === 'Other')
  //     profession = otherProfession;
  //   if (type === 'Other')
  //     type = otherType;
  //   onChange({ profession: profession, entityType: type, name, goal: profileGoal });
  // };

  const handleName = e => {
    setName(e.target.value);
    if (e.target.value !== '') {
      setInvalidName(false);
    } else {
      setInvalidName(true);
    }
  };

  // const handleGoal = (e, value) => {
  //   setProfileGoal(value.name);
  // };

  // const handleOtherProfession = p => {
  //   setOtherProfession(p.target.value);
  // }

  // const handleOtherType = t => {
  //   setOtherType(t.target.value);
  // }

  // const professions = [
  //   { name: 'Student' },
  //   { name: 'Marketer' },
  //   { name: 'Content Creator' },
  //   { name: 'Business Owner' },
  //   { name: 'Freelancer' },
  //   { name: 'Marketing Agency' },
  //   { name: 'Personal Branding' },
  //   { name: 'Other' }
  // ];

  // const types = [
  //   { name: 'Social Media Content' },
  //   { name: 'Ads & Retargeting' },
  //   { name: 'Prospecting & Email marketing' },
  //   { name: 'Product Explanatory videos' },
  //   { name: 'Other' }
  // ];

  // const goals = [
  //   { name: 'Create videos consistently for a brand' },
  //   { name: 'Create videos for a specific project' },
  //   { name: 'Create a single video' }
  // ];

  return (
    <Box>
      {loading && <Loader />}
      {!surveyCompleted ? (
        <>
          <Box className={classes.title} textAlign="center">
            Welcome, {username}
            <span role="img" aria-label="Hi" style={{ marginLeft: 8 }}>
              👋
            </span>
          </Box>
          <Box className={classes.subTitle}>
            For the best experience possible, tell us a little about yourself.
          </Box>
          {globalError && <ActionAlert message={globalError} className={classes.errorAlert} />}
          <Box marginBottom="24px" className={classes.autoCompleteContainer}>
            <Box className={classes.label}>First Name</Box>
            {invalidName && (
              <ActionAlert
                icon={<ReportProblemOutlinedIcon />}
                variant="text"
                message={'The First Name field is required'}
                className={classes.formAlert}
              />
            )}
            <input
              invalid={invalidName}
              className={clsx(classes.formInput, invalidName === true ? 'invalid' : null)}
              type="text"
              onChange={handleName}
              placeholder="Jane"
            />
          </Box>
          <Box marginBottom="24px" className={classes.autoCompleteContainer}>
            <Box className={classes.label}>What&apos;s your current role?</Box>
            <RemoteAutoComplete
              inputSettings={{
                placeholder: 'Select a role'
              }}
              fieldLabel="name"
              url={`${process.env.API_HOST}/professions?_sort=order:ASC`}
              headers={{
                Authorization: `Bearer ${jwt}`,
                'X-Mission-Control-Team': team
              }}
              autocompleteSettings={{
                style: {
                  width: '100%',
                  display: 'inline-flex',
                  marginRight: '10px'
                }
              }}
              onChange={handleProfession}
              value={profileProfession}
              className={classes.autocomplete}
            />
          </Box>
          <Box marginBottom="24px">
            <Box className={classes.label}>What will you be using WOXO for?</Box>
            {loadingList && <ListSkeleton />}
            {!loadingList && (
              <Grid container className={classes.gridContainer}>
                {entityTypes.map(item => (
                  <Grid key={item._id} item xs={6} sm={6} lg={6} xl={6}>
                    <Box
                      className={clsx(classes.item, item._id === profileType ? 'selected' : '')}
                      onClick={() => handleType(item._id)}
                    >
                      {item.name}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
          {/* <Box marginBottom="24px" className={classes.autoCompleteContainer}>
            <Box className={classes.label}>What best describes you?</Box>
            <RemoteAutoComplete
              inputSettings={{
                placeholder: 'Select a role'
              }}
              fieldLabel="name"
              defaultOptions={professions}
              autocompleteSettings={{
                style: {
                  width: '100%',
                  display: 'inline-flex',
                  marginRight: '10px'
                }
              }}
              onChange={handleProfession}
              value={profileProfession}
              className={classes.autocomplete}
            />
            {showOtherProfession && (
              <Box marginTop="24px" className={classes.autoCompleteContainer}>
                <Box className={classes.label}>Please Specify</Box>
                <input
                  className={clsx(classes.formInput)}
                  type="text"
                  onChange={handleOtherProfession}
                  placeholder="Carpenter"
                />
              </Box>
            )}
          </Box> */}
          {/* <Box marginBottom="24px" className={classes.autoCompleteContainer}>
            <Box className={classes.label}>What are you trying to solve by using WOXO?</Box>
            <RemoteAutoComplete
              inputSettings={{
                placeholder: 'Select a type'
              }}
              fieldLabel="name"
              defaultOptions={types}
              autocompleteSettings={{
                style: {
                  width: '100%',
                  display: 'inline-flex',
                  marginRight: '10px'
                }
              }}
              onChange={handleType}
              value={profileType}
              className={classes.autocomplete}
            />
            {showOtherType && (
              <Box marginTop="24px" className={classes.autoCompleteContainer}>
                <Box className={classes.label}>Please Specify</Box>
                <input
                  className={clsx(classes.formInput)}
                  type="text"
                  onChange={handleOtherType}
                  placeholder="Video maker"
                />
              </Box>
            )}
          </Box> */}
          {/* <Box marginBottom="24px" className={classes.autoCompleteContainer}>
            <Box className={classes.label}>Your goal with WOXO is to:</Box>
            <RemoteAutoComplete
              inputSettings={{
                placeholder: 'Select a goal'
              }}
              fieldLabel="name"
              defaultOptions={goals}
              autocompleteSettings={{
                style: {
                  width: '100%',
                  display: 'inline-flex',
                  marginRight: '10px'
                }
              }}
              onChange={handleGoal}
              value={profileGoal}
              className={classes.autocomplete}
            />
          </Box> */}
          <Box display="flex" justifyContent="center">
            <Button
              className={classes.startButton}
              disabled={loading || !profileType || !profileProfession || invalidName}
              onClick={handleStart}
              data-label="post-signup-survey-completed"
              data-type="btn-click"
            >
              Continue
            </Button>
          </Box>
        </>
      ) : (
        <>
          {/* <Box className={classes.infoText}>
            <span>{name}</span>, thank you for helping us make this experience the best for you!
          </Box>
          <Box className={classes.infoText__2}>
            See you online,
          </Box>
          <Box className={classes.infoText__2} style={{ marginBottom: 20 }}>
            Team WOXO
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              className={classes.startButton}
              onClick={handleFinish}
            >
              Continue
            </Button>
          </Box> */}
        </>
      )}
    </Box>
  );
};

UserProfessionJob.propTypes = {
  jwt: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profession: PropTypes.string,
  entityType: PropTypes.string,
  onChange: PropTypes.func
};

UserProfessionJob.defaultProps = {
  profession: null,
  entityType: null,
  onChange: () => { }
};

export default UserProfessionJob;
